module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<header>\r\n    <div class="header-placeholder"></div>\r\n    <div class="header">\r\n        <div class="header-left">\r\n            <div class="logo"><a href="index.html"><img src="' +
((__t = (require('./images/logo.png'))) == null ? '' : __t) +
'" alt=""></a></div>\r\n            <div class="nav">\r\n                <ul>\r\n                    <li class="active"><a href="index.html"><span>HOME</span></a></li>\r\n                    <li><a href="list_product.html"><span>PRODUCTS</span></a></li>\r\n                    <li><a href="about_profiles.html"><span>ABOUT BLACKLION</span></a></li>\r\n                    <li><a href="know_eu.html"><span>KNOW HOW</span></a></li>\r\n                    <li><a href="contact.html"><span>CONTACT US</span></a></li>\r\n                </ul>\r\n            </div>\r\n        </div>\r\n        <div class="header-right">\r\n            <div class="global"><a href="#"><i class="iconfont icon-world"></i><span>Global</span></a></div>\r\n            <div class="language">\r\n                <div class="now-language">EN <i class="iconfont icon-jiantou_xia"></i></div>\r\n                <div class="other-language">\r\n                    <a href="#" target="_blank">DE</a>\r\n                    <a href="#" target="_blank">CN</a>\r\n                </div>\r\n            </div>\r\n            <div class="hamburger hamburger-11" data-open="cmenu">\r\n                <div></div>\r\n            </div>\r\n        </div>\r\n    </div>\r\n    <div class="mob-nav">\r\n        <ul>\r\n            <li><a href="index.html">HOME</a></li>\r\n            <li><a href="list_product.html">PRODUCTS</a></li>\r\n            <li><a href="about_profiles.html">ABOUT BLACKLION</a></li>\r\n            <li><a href="know_eu.html">KNOW HOW</a></li>\r\n            <li><a href="contact.html">CONTACT US</a></li>\r\n        </ul>\r\n    </div>\r\n</header>';

}
return __p
}