import './index.scss'

var beforeScroH = $(document).scrollTop();
$(document).scroll(function () { 
    var scroH = $(document).scrollTop();  
    var viewH = $(window).height();
    var contentH = $(document).height(); 


    //判断页面是上滚还是下滚
    var delta = scroH - beforeScroH;
    if (delta > 0) {
        if (scroH > 600) {
            $('.header').addClass('tran')
        }
    } else if (delta < 0) {
        $('.header').removeClass('tran')
    }
    beforeScroH = scroH;

    if (scroH > 1000){
        $('.back-top').addClass('show')
    }else{
        $('.back-top').removeClass('show')
    }

    if (scroH > $('.footer').offset().top - viewH + 100 + $('.back-top').height()/2){
        $('.back-top').addClass('abs')
    }else{
        $('.back-top').removeClass('abs')
    }         
       
});

$('[data-open="cmenu"]').on('click', function () {
    if ($('.header .hamburger').hasClass('active')) {
        $('.header .hamburger').removeClass('active')
        $('.mob-nav').removeClass('active')
    } else {
        $('.header .hamburger').addClass('active')
        $('.mob-nav').addClass('active')
    }
})

// $(".header .pc-nav ul li").mouseenter(function () {
//     if (!$(this).hasClass('active')) {
//         $(this).addClass('hover');
//         $(this).siblings('.active').addClass('not-active');
//     }
// }).mouseleave(function () {
//     if (!$(this).hasClass('active')) {
//         $('.header .pc-nav ul li').removeClass('hover');
//         $('.header .pc-nav ul li.active').removeClass('not-active');
//     }
// });